import { useQuery } from '@apollo/client';
import AddSvg from '@lmc-eu/spirit-icons/svg/add.svg';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import ProfileCardMini from '@components/Profile/ProfileCard/ProfileCardMini';
import ProfileCardWithSuitableFor from '@components/Profile/ProfileCard/ProfileCardWithSuitableFor';
import { useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import { InterestingProfilesGalleryController, ProfileImpressionListData } from 'generated/phpConstants';
import {
  HomepagePromotedProfilesQuery,
  HomepagePromotedProfilesQueryVariables,
  ProfileCardData,
} from 'graphql/generated/types/graphqlTypes';
import { HOMEPAGE_PROMOTED_PROFILES } from 'graphql/queries/homepage';
import classes from './HomepageInterestingProfiles.module.scss';

interface Props {
  className?: string;
}

const HomepageInterestingProfiles = ({ className }: Props) => {
  const { t } = useTranslation();
  const getLocalizedRoute = useGetLocalizedRoute();

  const { data } = useQuery<HomepagePromotedProfilesQuery, HomepagePromotedProfilesQueryVariables>(
    HOMEPAGE_PROMOTED_PROFILES,
  );

  if (!data?.homepagePromotedProfiles) {
    return null;
  }

  const top3ProfileCards = data.homepagePromotedProfiles.slice(0, 3);
  const restProfileCards = data.homepagePromotedProfiles.slice(3);

  const interestingProfileGalleryUrl = getUrlWithParameters(getLocalizedRoute(Route.interestingProfileGallery), {
    zdroj: 'menu',
    premium: InterestingProfilesGalleryController.COUNT_OF_PREMIUM_COMPANIES_TO_SHOW,
  });

  return (
    <div className={className}>
      <div>
        <h2 className="typography-heading-medium-text mb-700">
          {t('INTERESTING_COMPANIES_HEADING_1', { ns: 'homepage' })}
        </h2>
        <div className={classes.HomepageInterestingProfiles__profileCardsWithSuitableFor}>
          {top3ProfileCards.map((profileCard, index) => (
            <ProfileCardWithSuitableFor
              key={`interesting_profile_card_${profileCard.profile.urlIdentifier}`}
              profileCardData={profileCard as ProfileCardData}
              impressionListName="promoted-premium-companies-homepage"
              impressionListVariant={ProfileImpressionListData.GTM_ATM_PAID_VARIANT}
              className={classes.HomepageInterestingProfiles__profileCardWithSuitableFor}
              targetBlank={true}
              position={index + 1}
            />
          ))}
          <a
            href={interestingProfileGalleryUrl}
            className={classNames(
              classes.HomepageInterestingProfiles__profileCardWithSuitableFor,
              classes.HomepageInterestingProfiles__profileCardWithSuitableForPlaceholder,
              'typography-body-small-text-regular',
            )}
          >
            <AddSvg />
            <div className="mt-600">{t('DISCOVER_OTHER_COMPANIES', { ns: 'homepage' })}</div>
          </a>
        </div>
        <div className="d-none d-tablet-block mt-700">
          <a href={interestingProfileGalleryUrl}>{t('DISCOVER_OTHER_COMPANIES', { ns: 'homepage' })}</a>
        </div>
      </div>
      <div className="mt-1300">
        <h3 className="typography-heading-xsmall-text">{t('INTERESTING_COMPANIES_HEADING_2', { ns: 'homepage' })}</h3>
        <div className={classes.HomepageInterestingProfiles__profileCardsMini}>
          {restProfileCards.map((profileCard, index) => (
            <ProfileCardMini
              key={`interesting_profile_card_${profileCard.profile.urlIdentifier}`}
              profileCardData={profileCard as ProfileCardData}
              impressionListName="promoted-premium-companies-homepage-mini"
              impressionListVariant={ProfileImpressionListData.GTM_ATM_PAID_VARIANT}
              targetBlank={true}
              position={index + 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomepageInterestingProfiles;
